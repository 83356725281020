.container{
    min-height: 50vh;
    padding: 10px;
    max-width: 700px;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    box-shadow: 0 0 5px rgb(178, 178, 178);
    border-radius: 5px;
}

.container h4{
    color: #414141;
}

.car_buttons_container{
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
}

.car_buttons_container button{
    padding: 10px;
    font-size: 16px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
}

.button{
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #d02629;
    font-size: 16px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.selected_car{
    border: 1px solid #d02629 !important;
    color: #d02629 !important;
}

@media(max-width: 767px){
    .container{
        margin-top: 0px;
    }
}