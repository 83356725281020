.about_us{
    margin-top: 50px;
    padding: 15px;
}

.about_words{
    margin-left: 50px;
}

@media(max-width: 767px){
    .about_words{
        margin-left: 0px;
    }
}