.banner_top{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../images/drivingacar.jpg);
    background-size:cover;
    height: 70vh;
    position: relative;
    padding: 20px;
}

.banner_top h1{
    font-size: 40px;
}

.banner_top p{
    font-size: 18px;
}

.banner_top{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.book_now_btn{
    margin-top: 30px;
    background-color: #d02629;
    padding: 15px;
    color: white;
    font-weight: 600;
}

.left_bottom_corner_white{
    position: absolute;
    bottom: 0;
    background-color: white;
    max-width: 450px;
    width: 100%;
    height: 70px;
    z-index: 1000;
    left: -10px;
}

.app_download{
    margin-top: 30px;
    padding: 20px;
}

.client_car_image_container{
    padding: 15px;
}

.about_us{
    margin-top: 50px;
    padding: 15px;
}

.about_words{
    margin-left: 50px;
}

.our_services{
    margin-top: 60px;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../images/city_with_cars.jpg);
    background-size:cover;
    height: 50vh;
    position: relative;
    padding: 20px;
}

.cards_container{
    margin-top: 60px;
}

.testimonial{
    background-color: #f7f7f7;
    margin-top: 250px;
}

.testimonial_content{
    min-height: 400px;
    margin-top: -16px;
    padding-top: 70px;
    padding-right: 50px;
}

.carousel_legend{
    position: relative;
    background-color: white;
    min-height: 300px;
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quotation_container{
    position: absolute;
    right: 0;
    top: -10px;
    background-color: #d02629;
    padding: 8px;
}

.why_us{
    min-height: 400px;
    background-color: #d02629;
    margin-top: -16px;
    padding: 70px;
}

@media(max-width: 767px){
    .client_car_image_container{
        display: none;
    }
    .left_bottom_corner_white{
        display: none;
    }

    .banner_top h1{
        font-size: 30px !important;
    }
    
    .banner_top p{
        font-size: 16px !important;
    }
    .testimonial{
        background-color: #f7f7f7;
        margin-top:1600px;
    }
    .testimonial_content{
        padding: 20px;
        margin-top: 30px;
    }
    .quotation_container{
        position: relative;
    }
    .about_words{
        margin-left: 0px;
        justify-content: left;
    }
    .why_us{
        margin-top: 20px;
        padding: 20px;
    }
    h1{
        font-size: 30px;
        font-weight: 500;
    }
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
    }
}

@media(max-width: 320px){
    .testimonial{
        margin-top:1680px;
    }
}

@media(max-width: 450px){
    .testimonial{
        margin-top:1450px;
    }
}