.footer{
    margin-top: 50px;
    background-color: #242323;
}

.right_phone_number{
    position: relative;
}

.colored_phone_number_container{
    position: absolute;
    background-color: #d02629;
    width: 100%;
    left: 0;
    top: -30px;
    padding: 15px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
}

.straight_skew{
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    margin-left: 30px;
}

.thick_divider{
    border: 2px solid #d02629;
    margin-top: -15px;
    width: 60px;
}

.link_container{
    margin-top: 15px;
}

.all_links_container{
    margin-top: 50px;
}

.link_container a{
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.copyright{
    margin-top: 60px;
    background-color: rgb(20, 20, 20);
    padding: 1px;
}

@media(max-width: 767px){
    .footer{
        padding: 20px;
    }

    .colored_phone_number_container{
        margin-top: 30px;
    }
    .all_links_container{
        margin-top: 120px;
    }
    .colored_phone_number_container{
        width: 90%;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
    }
    
    .straight_skew{
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
        margin-left: 30px;
    }

    .colored_phone_number_container{
        padding: 10px;
    }

    .colored_phone_number_container h3{
        font-size: 15px !important;
    }
}