.container{
    width: 100%;
}

.top{
    background-color: #f0f0f0;
}

.row_flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo_and_contact{
    background-color: white;
    padding: 0px;
}

.navbar{
    position: absolute;
    max-width: 1200px;
    width: 100%;
    background-color: #d02629;
    bottom: -20;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    z-index: 100;
}

.book_now_btn{
    padding: 20px;
    background-color: #242323;
    color: white;
    font-weight: 600;
    min-width: 150px;
}

.mobile_nav{
    display: none;
}

@media(max-width: 767px){
    .desktop_nav{
        display: none;
    }
    .mobile_nav{
        display: flex;
        flex-direction: row;
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .logo_and_contact{
        flex: 1;
    }
    .mobile_menu_list{
        padding: 15px;
        background-color: rgb(240, 240, 240);
        margin-top: 10px;
    }
}