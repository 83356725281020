.contact_banner{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../images/drivingacar.jpg);
    background-size:cover;
    position: relative;
    padding: 30px;
}

.form{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.form input{
    padding: 10px;
    width: 95%;
    outline: none;
    font-size: 16px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 5px;
    margin-top: 20px;
}

.form textarea{
    padding: 10px;
    width: 95%;
    outline: none;
    font-size: 16px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 5px;
}

.form button{
    padding: 10px;
    font-size: 16px;
    border: 1px solid #d02629;
    border-radius: 5px;
    background-color: #d02629;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}