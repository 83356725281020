.vehicles_banner{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../images/drivingacar.jpg);
    background-size:cover;
    position: relative;
    padding: 30px;
}

.vehicle_type{
    padding: 20px;
    margin-top: 10px;
    border: 1px solid gray;
    display: flex;
    flex-direction: row;
}

.vehicle_type div{
    margin-left: 25px;
}


@media(max-width: 767px){
    .vehicle_type{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .vehicle_type div{
        margin-left: 0px;
        text-align: center;
    }
}
