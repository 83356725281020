.inputs{
    border: 1px solid rgb(197, 197, 197);
    border-radius: 7px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.inputs input{
    width: 98%;
    font-size: 16px;
    height: 30px;
    outline: none;
    border: none;
    margin-left: 5px;
}